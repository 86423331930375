import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { JSON_API_URL } from '../../config';

const Post = () => {
  const { postId } = useParams();
  const navigate = useNavigate();

  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedPost, setEditedPost] = useState({});
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    fetchPost();
  }, []);

  const fetchPost = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${JSON_API_URL}/posts/${postId}`);
      setPost(response.data);
      setEditedPost(response.data);
    } catch (error) {
      console.error('Failed to fetch post', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedPost(post);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await axios.delete(`${JSON_API_URL}/posts/${postId}`);
      setAlertType('deleted');
      setTimeout(() => {
        setAlertType('');
        navigate(`/users/${post.userId}`);
      }, 2000);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await axios.put(`${JSON_API_URL}/posts/${postId}`, editedPost);
      setAlertType('edited');
      setTimeout(() => {
        setAlertType('');
      }, 3000);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update the post', error);
      setAlertType('error');
      setTimeout(() => {
        setAlertType('');
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPost((prevPost) => ({
      ...prevPost,
      [name]: value,
    }));
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {loading ? (
        <div className="flex justify-center items-center h-32">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      ) : (
        <div className="bg-white rounded shadow p-4">
          <h1 className="text-3xl font-bold mb-4">
            {isEditing ? (
              <input
                type="text"
                name="title"
                value={editedPost.title}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md px-4 py-2 w-full mb-2 focus:outline-none focus:border-blue-500"
              />
            ) : (
              post.title
            )}
          </h1>
          <p>
            {isEditing ? (
              <textarea
                name="body"
                value={editedPost.body}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md px-4 py-2 w-full mb-2 focus:outline-none focus:border-blue-500"
              />
            ) : (
              post.body
            )}
          </p>
          <div className="flex gap-2 mt-4">
            {isEditing ? (
              <>
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded-md"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded-md"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                  onClick={handleEdit}
                >
                  Edit
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-md"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </>
            )}
          </div>
          {alertType === 'edited' && (
            <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mt-4" role="alert">
              Post was successfully edited!
            </div>
          )}
          {alertType === 'deleted' && (
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-4" role="alert">
              Post was successfully deleted!
            </div>
          )}
          <Link
            to={`/users/${post.userId}`}
            className="bg-gray-500 text-white px-4 py-2 rounded-md mt-4 inline-block"
          >
            Back to User
          </Link>
        </div>
      )}
    </div>
  );
};

export default Post;
