import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { JSON_API_URL } from '../../config';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAllUsers, setShowAllUsers] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${JSON_API_URL}/users`);
      setUsers(response.data);
    } catch (error) {
      console.error('Failed to fetch users', error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleShowAllUsers = () => {
    setShowAllUsers(!showAllUsers);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Users</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name or username"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 rounded-md px-4 py-2 w-full"
        />
      </div>

      <div className="mb-4">
        <Link
          to="/create-user"
          className="bg-green-500 text-white px-4 py-2 rounded-md inline-block"
        >
          Create New User
        </Link>
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {loading ? (
          <LoadingSpinner />
        ) : (
          filteredUsers
            .slice(0, showAllUsers ? filteredUsers.length : 6)
            .map((user) => (
              <div key={user.id} className="bg-white rounded shadow-sm p-4">
                <h2 className="text-xl font-semibold mb-2">{user.name}</h2>
                <p className="text-gray-600 mb-2">Email: {user.email}</p>
                <p className="text-gray-600 mb-4">Username: {user.username}</p>
                <Link
                  to={`/users/${user.id}`}
                  className="bg-blue-500 text-white px-3 py-2 rounded-md inline-block"
                >
                  View Details
                </Link>
              </div>
            ))
        )}
      </div>
      {filteredUsers.length > 6 && (
        <button
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md mt-4"
          onClick={handleToggleShowAllUsers}
        >
          {showAllUsers ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  );
};

export default Users;
