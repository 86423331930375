import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { JSON_API_URL } from '../../config';

const CreateUser = () => {
  const navigate = useNavigate();

  const initialFormData = {
    name: '',
    username: '',
    email: '',
    street: '',
    suite: '',
    city: '',
    zipcode: '',
    phone: '',
    website: '',
    companyName: '',
    catchPhrase: '',
    bs: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [highestId, setHighestId] = useState(0);

  useEffect(() => {
    const fetchHighestId = async () => {
      try {
        const response = await axios.get(`${JSON_API_URL}/users?_sort=id&_order=desc&_limit=1`);
        const highestId = response.data[0]?.id || 0;
        setHighestId(highestId);
      } catch (error) {
        console.error('Failed to fetch highest user ID', error);
      }
    };

    fetchHighestId();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newUserId = highestId + 1;
      const response = await axios.post(`${JSON_API_URL}/users`, {
        id: newUserId,
        ...formData,
        address: {
          street: formData.street,
          suite: formData.suite,
          city: formData.city,
          zipcode: formData.zipcode,
        },
        company: {
          name: formData.companyName,
          catchPhrase: formData.catchPhrase,
          bs: formData.bs,
        },
      });
      console.log('User created:', response.data);
      navigate(`/users/`);
    } catch (error) {
      console.error('Failed to create user', error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Create New User</h1>
      <form onSubmit={handleSubmit} className="bg-white rounded shadow p-4">
        <label className="block mb-2">
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
            required
          />
        </label>
        <label className="block mb-2">
          Username:
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
            required
          />
        </label>
        <label className="block mb-2">
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
            required
          />
        </label>
        <label className="block mb-2">
          Street:
          <input
            type="text"
            name="street"
            value={formData.street}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
          />
        </label>
        <label className="block mb-2">
          Suite:
          <input
            type="text"
            name="suite"
            value={formData.suite}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
          />
        </label>
        <label className="block mb-2">
          City:
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
          />
        </label>
        <label className="block mb-2">
          Zipcode:
          <input
            type="text"
            name="zipcode"
            value={formData.zipcode}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
          />
        </label>
        <label className="block mb-2">
          Phone:
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
          />
        </label>
        <label className="block mb-2">
          Website:
          <input
            type="url"
            name="website"
            value={formData.website}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
          />
        </label>
        <label className="block mb-2">
          CompanyName:
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
          />
        </label>
        <label className="block mb-2">
          CatchPhrase:
          <input
            type="text"
            name="catchPhrase"
            value={formData.catchPhrase}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
          />
        </label>
        <label className="block mb-2">
          Bs:
          <input
            type="text"
            name="bs"
            value={formData.bs}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md px-4 py-2 w-full mt-1"
          />
        </label>
        <div className="flex justify-end mt-4">
          <Link
            to="/users"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Cancel
          </Link>
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-md ml-4 hover:bg-green-600"
          >
            Create User
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUser;
